@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Book.eot');
  src: url('./assets/fonts/Avenir-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Book.woff2') format('woff2'), url('./assets/fonts/Avenir-Book.woff') format('woff'),
    url('./assets/fonts/Avenir-Book.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Medium.eot');
  src: url('./assets/fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Medium.woff2') format('woff2'), url('./assets/fonts/Avenir-Medium.woff') format('woff'),
    url('./assets/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: normals;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir-Heavy.eot');
  src: url('./assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Avenir-Heavy.woff2') format('woff2'), url('./assets/fonts/Avenir-Heavy.woff') format('woff'),
    url('./assets/fonts/Avenir-Heavy.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Avenir', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.ql-mention-list-container {
  width: 270px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.ql-mention-loading {
  line-height: 44px;
  padding: 0 20px;
  vertical-align: middle;
  font-size: 16px;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.disabled {
  cursor: auto;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 8px;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
}

.mention > span {
  margin: 0 3px;
}

.mention[data-denotation-char="$$"] {
  background-color: #ffd650;
}

.mention[data-denotation-char="@@"] {
  background-color: #5f6cfb;
  color:rgba(255, 255, 255, 0.9);
}

.mention-error {
  display: block;
  height: 24px;
  border-radius: 8px;
  padding: 3px 0;
  margin-right: 2px;
  user-select: all;
  background-color: rgb(213, 71, 71);
  color:rgba(255, 255, 255, 0.9)
}

.mention[data-id="routes"] ~ *:not(.mention[data-id="end-routes"]) {
  color: red;
}

.chakra-portal {
  z-index: 1500;
  position: relative;
}